import React from 'react'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Layout from '../components/layout'
import DonationForm from '../components/ui/DonationForm'
import Seo from '../components/seo';

const promise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

function Donate() {
  return (
    <Layout>
      <Seo title="Donate to AWRA" />
      <section className="container">
        <Elements stripe={promise}>
          <DonationForm />
        </Elements>
      </section>
    </Layout>
  )
}

export default Donate
