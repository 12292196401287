import React, { useState } from "react"
import { navigate } from "gatsby"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { MdEuroSymbol } from "@react-icons/all-files/md/MdEuroSymbol"

import ByStripe from "../../../assets/images/svgs/stripe.svg"
import "./style.scss"

export default function DonationForm() {
  const [metadata, setMetadata] = useState({
    donorName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  })
  const [amount, setAmount] = useState(5)
  // const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [custom, setCustom] = useState(false)
  // const [disabled, setDisabled] = useState(true)
  // const [clientSecret, setClientSecret] = useState("")
  const stripe = useStripe()
  const elements = useElements()

  const handleInputChange = async ev => {
    ev.preventDefault()
    const {
      target: { name, value },
    } = ev

    setMetadata(perv => ({
      ...perv,
      [name]: value,
    }))
  }

  const handleAmountChange = async ev => {
    const {
      target: { value },
    } = ev

    setAmount(parseFloat(value))
  }

  const handleCustomChange = async () => {
    // const {
    //   target: { value },
    // } = ev
    // setAmount(0)
    setCustom(!custom)
  }

  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    // setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }

  const resetForm = async () => {
    setMetadata({
      donorName: "",
      email: "",
    })
    setAmount(0)
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    setProcessing(true)

    const cardElem = elements.getElement(CardElement)

    await fetch("/api/payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
      body: JSON.stringify({
        amount,
        receipt_email: metadata.email,
        metadata: {
          donorName: metadata.donorName,
          email: metadata.email,
        },
      }),
    })
      .then(res => {
        return res.clone().json()
      })
      .then(data => {
        return stripe.confirmCardPayment(data.clientSecret, {
          payment_method: {
            type: "card",
            card: cardElem,
          },
        })
      })
      .then(paymentResult => {
        if (paymentResult.error) {
          setError(`Payment failed: ${paymentResult.error.message}`)
          setProcessing(false)
        } else {
          if (paymentResult.paymentIntent.status === "succeeded") {
            setError(null)
            setProcessing(false)
            resetForm()
            cardElem.clear()
            navigate("/thank-you/")
          }
        }
      })
  }

  return (
    <div className="donation">

      <form id="form" className="form-wrapper" onSubmit={handleSubmit}>
        {stripe ? (
          <>
            <div className="form-header">
              <h2 className="form-title">Donate to support AWRA!</h2>
            </div>
            {error ? (
              <div className="notification is-danger is-light">
                {error}
              </div>
            ) : (
              ""
            )}
            <div className="switches">
              <label className="input-wrapper">
                <input
                  type="radio"
                  name="amount"
                  value="5"
                  onChange={handleAmountChange}
                  onClick={() => setCustom(false)}
                  checked={amount === 5 && custom === false}
                />
                <span>€</span>
                <span>5</span>
                <span className="-background"></span>
              </label>
              <label className="input-wrapper">
                <input
                  type="radio"
                  name="amount"
                  value="25"
                  onChange={handleAmountChange}
                  onClick={() => setCustom(false)}
                  checked={amount === 25 && custom === false}
                />
                <span>€</span>
                <span>25</span>
                <span className="-background"></span>
              </label>
              <label className="input-wrapper">
                <input
                  type="radio"
                  name="amount"
                  value="50"
                  onChange={handleAmountChange}
                  onClick={() => setCustom(false)}
                  checked={amount === 50 && custom === false}
                />
                <span>€</span>
                <span>50</span>
                <span className="-background"></span>
              </label>
              <label className="input-wrapper">
                <input
                  type="radio"
                  name="amount"
                  value="100"
                  onChange={handleAmountChange}
                  onClick={() => setCustom(false)}
                  checked={amount === 100 && custom === false}
                />
                <span>€</span>
                <span>100</span>
                <span className="-background"></span>
              </label>
              <label className="input-wrapper">
                <input
                  type="checkbox"
                  name="amount"
                  value={custom}
                  onChange={handleCustomChange}
                  checked={custom}
                />
                <span>Custom Amount</span>
                <span className="-background"></span>
              </label>
            </div>
            {custom ? (
              <div className="custom-amount">
                <p className="-title">Custom Donation Amount</p>
                <div className="control has-icons-left">
                  <input
                    className="input"
                    name="customAmount"
                    type="number"
                    placeholder="Amount"
                    value={amount}
                    onChange={handleAmountChange}
                  />
                  <span className="icon is-small is-left">
                    <MdEuroSymbol size="24" />
                  </span>
                </div>
              </div>
            ) : null}
            <div className="card-wrapper">
              <p className="-title">Card Info</p>
              <CardElement
                id="card-element"
                onChange={handleChange}
                className="card-element"
                options={{
                  style: {
                    base: {
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "1.2rem",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
            </div>
            <div className="donor-info">
              <p className="-title">Donor</p>
              <input
                className="input"
                name="donorName"
                type="text"
                placeholder="Name"
                value={metadata.donorName}
                onChange={handleInputChange}
              />
              <input
                className="input"
                name="email"
                type="email"
                placeholder="Email"
                value={metadata.email}
                onChange={handleInputChange}
              />
            </div>
            <button
              className={`button is-primary ${
                processing ? " is-loading" : null
              }`}
              disabled={processing ? true : false}
              type="submit"
            >
              Donate {amount ? "€" + amount : ""}
            </button>
          </>
        ) : (
          <progress className="progress is-small is-primary" max="100">
            15%
          </progress>
        )}
      </form>
      <ByStripe />
    </div>
  )
}
